<template>
  <BaseModal
    name="antecipation"
    size="lg"
    title="⚡ Antecipação de saldo"
    @shown="openModal"
    @hidden="reset"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="!loading">
      <div class="item-top">
        <img src="@/assets/img/icons/money-anticipation.svg" />
        <div class="money">
          <span>Disponível para Antecipação</span>
          <p>R$ {{ antecipation ? formatMoney(antecipation) : "0,00" }}</p>
        </div>
      </div>

      <div class="faturas mt-3" v-if="faturas.length">
        <p>
          Abaixo estamos listando as faturas que estão disponíveis para
          antecipação
        </p>

        <div class="lista-faturas">
          <!-- <div class="top-faturas"> -->
          <b-row>
            <b-col md="6" cols="12">
              <b-form-group
                label=""
                label-for="antecipar-tudo"
                class="selecionar-tudo"
              >
                <b-form-checkbox
                  name="antecipar-tudo "
                  size="lg"
                  switch
                  v-model="antecipa_tudo"
                  :disabled="loading_update"
                  @change="changeSelectedAll"
                >
                  <p class="info-checkbox">
                    Quero antecipar tudo
                    <span class="descricao-checkbox"
                      >Selecionar todas as faturas disponíveis para
                      antecipação</span
                    >
                  </p>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="5" cols="12">
              <b-form-group
                label="Valor desejado"
                label-for="valor-antecipar"
                id="valor-antecipar"
              >
                <money
                  v-model="valor_desejado"
                  v-bind="money"
                  v-validate="'required'"
                  autocomplete="off"
                  :disabled="antecipa_tudo || loading_update"
                  id="money-valor"
                  class="w-100"
                >
                </money>
                <p>
                  {{
                    !antecipa_tudo
                      ? "Com base no valor desejado, calculamos as faturas que a soma aproximam deste valor"
                      : "Você está antencipando todas as faturas."
                  }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="7" cols="12">
              <div
                v-if="opcoes_aproximadas.length"
                data-anima="top"
                class="w-100"
              >
                <b-form-group
                  class="mb-3"
                  label="Opções aproximadas. Selecione a desejada"
                >
                  <b-form-invalid-feedback :state="state" class="mb-2"
                    >Selecione uma opção</b-form-invalid-feedback
                  >
                  <b-form-radio-group
                    v-model="selected_aproximado"
                    :options="opcoes_aproximadas"
                    :state="state"
                    name="radio-validation"
                    @change="changeAproximadas"
                  >
                  </b-form-radio-group>
                </b-form-group>
              </div>
            </b-col>
          </b-row>

          <div class="item-detalhe mt-4">
            <p class="resumo">Resumo</p>
          </div>

          <div class="item-detalhe">
            <p>Antecipação solicitada</p>
            <p class="destaque" :class="{ load_valor: loading_update }">
              R$ {{ solicitado ? formatMoney(solicitado) : "0,00" }}
            </p>
          </div>

          <div class="item-detalhe">
            <p class="tarifas">Taxa de antecipação: {{ taxa_antecipacao }}%</p>
            <p class="tarifa" :class="{ load_valor: loading_update }">
              -R$ {{ tarifa ? formatMoney(tarifa) : "0,00" }}
            </p>
          </div>

          <div class="item-detalhe mb-3">
            <p>Será antecipado</p>
            <p class="destaque" :class="{ load_valor: loading_update }">
              R$ {{ antecipacao ? formatMoney(antecipacao) : "0,00" }}
            </p>
          </div>
        </div>
      </div>

      <div class="faturas mt-3" v-if="!faturas.length">
        <b-row key="nao-encontrado" class="justify-content-center">
          <p class="nao-encontrado">Você não possui faturas para antecipar</p>
        </b-row>
      </div>
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton
        variant="link-info"
        class="mr-4"
        @click="cancel"
        :disabled="loading || loading_update"
      >
        Cancelar
      </BaseButton>
      <div id="btn-antecipar">
        <BaseButton
          variant="info3"
          @click="onSubmit"
          :disabled="
            loading || loading_update || !faturas.length || !solicitado
          "
        >
          Antecipar agora
        </BaseButton>
        <!-- solicitado > antecipation -->
      </div>
      <b-tooltip
        v-if="!faturas.length"
        target="btn-antecipar"
        title="Você não possui valores para antecipar"
      />
      <b-tooltip
        v-if="!solicitado"
        target="btn-antecipar"
        title="Nenhuma fatura selecionada para antecipar"
      />
      <!-- <b-tooltip
        v-if="solicitado > antecipation"
        target="btn-antecipar"
        title="Valor selecionado maior do que o disponível"
      /> -->
      <b-tooltip
        v-if="loading_update"
        target="btn-antecipar"
        title="Calculando valores da antecipação..."
      />
    </template>
  </BaseModal>
</template>

<script>
import SaleService from "@/services/resources/SaleService";
const serviceSales = SaleService.build();
import GlobalSettings from "@/services/resources/GlobalSettings";
const serviceGlobalSettings = GlobalSettings.build();

import AntecipationService from "@/services/resources/AntecipationService";
const serviceAntecipation = AntecipationService.build();

import { Money } from "v-money";
import FormatMoney from "@/mixins/money";
import _ from "lodash";

export default {
  name: "Antecipation",
  data() {
    return {
      ocultar: false,
      loading: false,
      loading_update: false,
      faturas: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      ver_detalhes: false,
      valor_desejado: 0,
      selectedFatura: [],
      itemFatura: [],
      antecipa_tudo: true,
      solicitado: 0,
      tarifa: 0,
      antecipacao: 0,
      taxa_antecipacao: 0,
      opcoes_antecipacao: null,
      opcoes_aproximadas: [],
      selected_aproximado: null,
    };
  },
  components: {
    Money,
  },
  mixins: [FormatMoney],

  props: {
    antecipation_status: {},
    antecipation: {
      type: Number,
      default: 0,
    },
    antecipation_fee: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    valor_desejado() {
      this.debounce();
    },
    solicitado() {
      this.antecipacao = this.solicitado - this.tarifa;
    },
  },
  computed: {
    state() {
      return Boolean(this.selected_aproximado);
    },
  },
  methods: {
    changeAproximadas(event) {
      this.solicitado = event.amount;
      this.tarifa = event.fee;
      this.selectedFatura = event.sales;
    },
    roundValue(num, places) {
      return +parseFloat(num).toFixed(places);
    },
    reset(desejado = false) {
      this.solicitado = 0;
      this.tarifa = 0;
      this.antecipacao = 0;
      this.selected_aproximado = null;
      if (!desejado) {
        this.valor_desejado = 0;
      }
      this.opcoes_aproximadas = [];

      // this.changeSelectedAll(false);
    },
    openModal() {
      this.fetchSales();
      this.antecipa_tudo = true;
      this.taxa_antecipacao = this.antecipation_fee;
      if (!this.taxa_antecipacao) {
        this.fetchGlobalSettings();
      }
    },
    debounce: _.debounce(function() {
      this.calculaValor();
    }, 500),
    calculaValor() {
      if (
        (!this.valor_desejado &&
          this.roundValue(this.valor_desejado) !=
            this.roundValue(this.solicitado)) ||
        (this.valor_desejado && !this.antecipa_tudo)
      ) {
        this.reset(true);
        this.loading_update = true;

        let data = {
          amount: this.valor_desejado,
        };
        serviceAntecipation
          .create(data, "/amount")
          .then((response) => {
            // MENOR QUE
            if (response.before.amount && response.before.sales.length) {
              this.opcoes_aproximadas.push({
                text: `Quero antecipar R$ ${this.formatMoney(
                  response.before.amount
                )} (${response.before.sales.length} faturas)`,
                value: response.before,
              });
            }

            // MAIOR QUE
            if (response.after.amount && response.after.sales.length) {
              this.opcoes_aproximadas.push({
                text: `Quero antecipar R$ ${this.formatMoney(
                  response.after.amount
                )} (${response.after.sales.length} faturas)`,
                value: response.after,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loading_update = false;
          });
      }
    },

    debounceUpdateValues: _.debounce(function() {
      this.atualizaValores();
    }, 500),

    atualizaValores() {
      this.loading_update = true;
      let data = {
        sales: [...this.faturas.map((item) => item.id)],
      };
      serviceAntecipation
        .create(data, "/check")
        .then((response) => {
          this.solicitado = response.amount;
          this.tarifa = response.fee;
          this.valor_desejado = this.solicitado;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading_update = false;
        });
    },
    changeSelectedAll(event) {
      this.reset();
      this.selectedFatura = [];

      if (event) {
        this.itemFatura.forEach((item) => {
          item.active = true;
          this.selectedFatura.push(item.id);
        });

        this.debounceUpdateValues();
      } else {
        this.antecipa_tudo = false;
        this.itemFatura.forEach((item) => {
          item.active = false;
        });
        this.solicitado = 0;
        this.tarifa = 0;
        this.selectedFatura = [];
        this.valor_desejado = 0;
      }
    },

    onSubmit() {
      this.loading = true;
      let data = {
        sales: this.selectedFatura,
      };

      serviceAntecipation
        .create(data)
        .then(() => {
          this.$emit("close");
          this.$emit("pending");
          this.$bvModal.hide("antecipation");
          if (this.antecipation_status === "AUTOMATICALLY_APPROVED") {
            this.$bvToast.toast(
              "Antecipação realizada. O valor já está disponível para saque",
              {
                title: "Saque",
                variant: "success",
                autoHideDelay: 9000,
                appendToast: true,
              }
            );
          } else {
            this.$bvToast.toast(
              "Antecipação pendente. Em breve valor estará disponível para saque",
              {
                title: "Saque",
                variant: "success",
                autoHideDelay: 9000,
                appendToast: true,
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchSales() {
      this.loading = true;
      this.itemFatura = [];

      let data = {
        antecipation: true,
      };
      serviceSales
        .search(data)
        .then((response) => {
          // this.faturas = response.data.data;
          this.faturas = response;

          this.faturas.forEach((item) => {
            this.itemFatura.push({
              active: false,
              id: item.id,
            });
          });

          this.changeSelectedAll(true);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchGlobalSettings() {
      let data = {
        keys: "ANTECIPATION_FEE",
      };
      serviceGlobalSettings.search(data).then((response) => {
        response.forEach((item) => {
          if (item.key == "ANTECIPATION_FEE") {
            this.taxa_antecipacao = +item.value;
          }
        });
      });
    },
  },
  mounted() {
    this.fetchGlobalSettings();
  },
};
</script>

<style scoped>
p {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
}
.item-top {
  display: flex;
  align-items: center;
  gap: 15px;
}

.item-top + .item-top {
  margin-top: 20px;
}
.item-top span {
  font-size: 12px;
  font-weight: normal;
  color: #81858e;
}
.item-top p {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  color: #333;
}
.info-checkbox {
  margin-left: 5px;
  cursor: pointer;
  max-width: 300px;
  font-weight: 600;
  color: #333;
  margin: 0;
}
.descricao-checkbox {
  font-size: 14px;
}

.top-faturas {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .top-faturas {
    display: block;
  }
}
.header-table-faturas {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}
.item-fatura {
  border-top: 1px #ededf0 solid;
  padding: 10px 0;
  font-size: 14px;
  align-items: center;
}
.item-fatura div {
  color: #81858e;
}
.item-antecipa {
  cursor: pointer;
}
.ver-detalhes {
  font-weight: 600;
  font-size: 14px;
  color: #333;
}
.ver-detalhes.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.item-detalhe {
  /* padding: 0 15px 0 10px; */
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item-detalhe + .item-detalhe {
  margin-top: 10px;
}
.item-detalhe p.resumo {
  color: #2a63ab;
}
.item-detalhe p.destaque {
  color: #333;
}
.item-detalhe p.tarifa {
  color: #ff0c37;
}
.item-detalhe p {
  margin: 0;
  font-size: 15px;
}
.item-detalhe p.load_valor {
  background: rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  transition: 0.3s;
  color: transparent;
  padding-left: 20px;
}
#money-valor:disabled {
  color: rgba(0, 0, 0, 0.5) !important;
}
.scroll-faturas {
  padding: 0 10px;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}
@media screen and (max-width: 768px) {
  .item-detalhe p {
    margin: 0;
    font-size: 14px;
  }
}
.descricao-checkbox {
  max-width: 100%;
}
</style>
