<template>
  <div>
    <BaseModal
      name="bank-account"
      size="lg"
      title="Contas Bancárias"
      @hidden="resetFields"
      @shown="fetchBankAccount"
    >
      <!-- <h5 class="mt-3 mb-4">Adicionar Conta</h5> -->
      <b-row>
        <b-col cols="12">
          <div class="d-flex justify-content-center" v-if="loading">
            <b-spinner label="Loading..."></b-spinner>
          </div>

          <b-form
            novalidate
            v-if="
              !loading &&
                (status_validation.status == 'APPROVED' ||
                  (user.company
                    ? checkStatus(user.company.documents, 'APPROVED').length > 0
                    : false))
            "
          >
            <b-row class="">
              <b-col cols="12" md="3" class="mr-3">
                <b-form-group label="Código do banco" label-for="bank_number">
                  <b-form-input
                    id="bank_number"
                    name="bank_number"
                    v-model="bank_number"
                    v-validate="'required'"
                    autocomplete="off"
                    placeholder="- - -"
                    type="text"
                    disabled
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('bank_number')">
                    Digite o Código do banco.
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="8" class="banco mt-3 mt-md-0 mb-3">
                <b-form-group label="Nome do Banco" label-for="bank_name">
                  <b-form-input
                    id="bank_name"
                    name="bank_name"
                    v-model="bank_name"
                    v-validate="'required'"
                    autocomplete="off"
                    placeholder="Pesquise seu banco"
                    type="text"
                    disabled
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('bank_name')">
                    Digite o nome do banco.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-col class="search_bank" v-b-modal.bank-account-bank-list>
                  <img src="@/assets/img/icons/search.svg" class="" />
                  Pesquisar banco
                </b-col>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col cols="7" class="mr-3">
                <b-form-group label="Agência" label-for="agency">
                  <b-form-input
                    id="agency"
                    name="agency"
                    v-model="agency"
                    v-validate="'required'"
                    v-mask="'#########'"
                    autocomplete="off"
                    placeholder="0000"
                    type="text"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('agency')">
                    Digite a agência.
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="DV (opcional)" label-for="agency_dv">
                  <b-form-input
                    id="agency_dv"
                    v-mask="'#'"
                    v-model="agency_dv"
                    autocomplete="off"
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="7" class="mr-3 mb-3">
                <b-form-group label="Conta" label-for="account">
                  <b-form-input
                    id="account"
                    name="account"
                    v-model="account"
                    v-validate="'required'"
                    v-mask="'############'"
                    autocomplete="off"
                    placeholder="00000"
                    type="text"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('account')">
                    Digite a conta.
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group label="DV" label-for="account_dv">
                  <b-form-input
                    id="account_dv"
                    name="account_dv"
                    v-model="account_dv"
                    v-validate="'required'"
                    v-mask="'#'"
                    autocomplete="off"
                    placeholder="0"
                    type="text"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('account_dv')">
                    Dígito verificador.
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" class="mr-4">
                <b-form-group label="Tipo de conta" label-for="type">
                  <b-form-select
                    v-model="type"
                    name="type"
                    :options="accountTypes"
                    v-validate="'required'"
                  ></b-form-select>
                  <b-form-invalid-feedback :state="!errors.has('type')">
                    Selecione o tipo.
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="5">
                <b-form-group label="Modalidade" label-for="modality">
                  <b-form-select
                    v-model="modality"
                    name="modality"
                    :options="accountModality"
                    v-validate="'required'"
                  ></b-form-select>
                  <b-form-invalid-feedback :state="!errors.has('modality')">
                    Selecione a modalidade.
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-2 mr-2 mb-3"> </b-row>
          </b-form>
          <div
            class="sem-docs"
            v-if="
              !loading &&
                status_validation.status != 'APPROVED' &&
                (user.company
                  ? (checkStatus(user.company.documents, 'DISAPPROVED')
                      .length ||
                      checkStatus(user.company.documents, 'REQUESTED').length ||
                      !user.company.documents.length) &&
                    !checkStatus(user.company.documents, 'APPROVED').length
                  : true)
            "
          >
            <div
              v-if="status_validation.status == 'REQUESTED'"
              class="aguardando-analise"
            >
              <p>
                Sua verificação de identidade já foi enviada para nossa equipe e
                está em processo de análise.
              </p>
              <p>
                Aguarde, em breve receberá o retorno com o resultado da análise.
              </p>
            </div>
            <div v-else>
              <p>
                Ops, vimos que sua verificação de identidade ainda não foi
                feita.
              </p>
              <p>
                Para adicionar uma conta bancária, primeiro é necessário que
                faça a verificação de identidade.
              </p>
              <BaseButton variant="info3" @click="initVerification"
                >Iniciar a verificação de identidade</BaseButton
              >
            </div>
          </div>
        </b-col>
      </b-row>

      <h5 class="mt-3 mb-4 titulo-contas" v-if="!loading">
        Contas cadastradas
      </h5>

      <template v-if="!loading && bankAccounts.length > 0">
        <b-row class="header-table-contas">
          <b-col cols="12" md="5" class="d-none d-md-block"> Banco </b-col>
          <b-col cols="12" md="2" class="d-none d-md-block"> Agência </b-col>
          <b-col cols="12" md="2" class="d-none d-md-block"> Conta </b-col>
          <b-col cols="12" md="3" class="d-none d-md-block"> Ações </b-col>
          <b-col cols="12" md="3" class="d-md-none d-block">
            Dados da Conta
          </b-col>
        </b-row>

        <b-row
          v-for="item in bankAccounts"
          :key="item.id"
          class="BankAccount-item"
          data-anima="top"
        >
          <b-col cols="12" md="5">
            <div>
              • {{ item.bank_number }} -

              {{
                item.bank_name.length > 30
                  ? item.bank_name.substr(0, 30) + "..."
                  : item.bank_name
              }}
            </div>
            <p class="mt-md-2">
              <span class="d-inline">• Documento - </span>
              {{ item.cpf_cnpj }}
            </p>
          </b-col>
          <b-col cols="12" md="2">
            <span class="d-md-none d-inline">• Agência - </span>
            {{ item.agency }}
          </b-col>
          <b-col cols="12" md="2">
            <span class="d-md-none d-inline">• Conta - </span>
            {{ item.account }}-{{ item.account_dv }}
          </b-col>
          <b-col cols="12" md="3" class="d-flex">
            <b-button
              type="button"
              class="btn-novo btn-editar"
              @click="loadEdit(item)"
              >Editar</b-button
            >
          </b-col>
        </b-row>

        <p class="info-remove">
          Caso queira remover uma conta, entre em contato com o suporte
        </p>
      </template>

      <div
        v-if="loading && bankAccounts.length == 0"
        class="table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>

      <b-row
        v-if="!loading && bankAccounts.length == 0"
        class="table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhuma conta foi encontrado</p>
      </b-row>

      <template v-slot:footer="{ cancel }">
        <BaseButton
          variant="secondary_outline"
          @click="cancel"
          class="mr-3"
          :disabled="loading"
        >
          Fechar
        </BaseButton>
        <BaseButton
          variant="secondary"
          @click="onSubmit"
          :disabled="
            loading ||
              (status_validation.status != 'APPROVED' &&
                (user.company
                  ? !checkStatus(user.company.documents, 'APPROVED').length
                  : true))
          "
        >
          {{ !update ? "Adicionar conta" : "Salvar conta" }}
        </BaseButton>
      </template>
    </BaseModal>

    <!-- Modal Bank List -->
    <modal-bank-account-bank-list @select="selectBank" />

    <ModalValidation @close="getStatusValidation" />
  </div>
</template>
<script>
import BankAccountService from "@/services/resources/BankAccountService";
import ModalBankAccountRemove from "./bankAccountRemove";
import ModalBankAccountBankList from "./bankAccountBankList";
import ValidationService from "@/services/resources/ValidationService";
const serviceValidation = ValidationService.build();

const service = BankAccountService.build();

export default {
  components: {
    ModalBankAccountRemove,
    ModalBankAccountBankList,
  },
  props: {
    value: {
      type: Number,
    },
  },
  computed: {
    userId() {
      return this.$store.getters.getProfile.id;
    },
  },
  data() {
    return {
      status_validation: {
        status: "",
        observation: "",
      },
      bank_number: null,
      bank_name: null,
      agency: null,
      agency_dv: null,
      account: null,
      account_dv: null,
      modality: null,
      type: null,
      // cpf_cnpj: null,
      name: null,
      accountModality: [
        { value: null, text: "Modalidade" },
        { value: "CURRENT", text: "Conta Corrente" },
        { value: "SAVINGS", text: "Conta Poupança" },
      ],
      accountTypes: [{ value: null, text: "Tipo de conta" }],

      bankAccounts: [],
      bank_account_id: -1,
      submit: false,
      loading: false,
      update: false,
      user: {
        documents: [],
        company: {
          documents: [],
        },
      },
    };
  },
  methods: {
    checkStatus(docs, status) {
      if (docs.length) {
        return docs.filter((item) => item.status == status);
      } else return [];
    },
    initVerification() {
      this.$bvModal.show("identity-validation");
    },
    loadEdit(item) {
      document
        .querySelector("#bank-account")
        .scrollTo({ top: 0, behavior: "smooth" });
      if (document.querySelector("#bank_number")) {
        document.querySelector("#bank_number").focus();
      }
      this.bank_number = item.bank_number;
      this.bank_name = item.bank_name;
      this.agency = item.agency;
      this.agency_dv = item.agency_dv;
      this.account = item.account;
      this.account_dv = item.account_dv;
      this.name = item.name;
      // this.cpf_cnpj = item.cpf_cnpj;
      this.bank_account_id = item.id;
      this.type = item.account_type;
      this.modality = item.type;
      this.update = true;
    },
    selectBank(item) {
      this.bank_number = item.code;
      this.bank_name = item.name;
    },
    openConfirmRemove(id) {
      this.bank_account_id = id;
      this.$bvModal.show("bank-account-remove");
    },
    resetFields() {
      this.bank_number = null;
      this.bank_name = null;
      this.agency = null;
      this.agency_dv = null;
      this.account = null;
      this.account_dv = null;
      this.type = null;
      this.modality = null;
      this.name = null;
      // this.cpf_cnpj = null;
      this.bank_account_id = -1;
      this.update = false;
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit = true;
          this.loading = true;
          let data = {
            bank_number: this.bank_number,
            bank_name: this.bank_name,
            agency: this.agency,
            agency_dv: this.agency_dv,
            account_dv: this.account_dv,
            account: this.account,
            type: this.modality,
            account_type: this.type,
            name: this.name,
            // cpf_cnpj: this.cpf_cnpj,
          };

          if (!this.update) {
            service
              .create(data)
              .then((response) => {
                this.submit = false;
                this.loading = false;
                this.$bvToast.toast("Sua conta foi adicionada com sucesso!", {
                  title: "Conta bancária",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });

                this.resetFields();
                this.$emit("close");
                this.$bvModal.hide("bank-account");
              })
              .catch(() => {
                this.submit = false;
                this.loading = false;
              })
              .finally(() => {
                this.submit = false;
                this.loading = false;
              });
          } else {
            // On editing

            data.id = this.bank_account_id;

            service
              .update(data)
              .then(() => {
                this.submit = false;
                this.loading = false;
                this.$bvToast.toast("Sua conta foi atualizada com sucesso!", {
                  title: "Conta bancária",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });

                this.resetFields();
                this.$emit("close");
                this.$bvModal.hide("bank-account");
              })
              .finally(() => {
                this.submit = false;
                this.loading = false;
              });
          }
        } else {
          this.submit = false;
          this.loading = false;

          this.$bvToast.toast("Valide os campos obrigatórios", {
            title: "Conta bancária",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      });
    },
    fetchBankAccount() {
      this.loading = true;
      this.getStatusValidation();

      service.search().then((response) => {
        this.bankAccounts = response.reverse();
        this.loading = false;
      });
    },
    getStatusValidation() {
      this.loading = true;
      let data = {
        id: this.userId,
      };
      serviceValidation
        .read(data)
        .then((response) => {
          this.status_validation = response;
          if (!this.status_validation.status) {
            this.status_validation = {
              status: "INITIATED",
              observation: "",
            };
          }

          if (this.status_validation.status == "APPROVED") {
            this.accountTypes.push({
              value: "PERSONAL",
              text: "Conta Pessoal (CPF)",
            });
          } else {
            this.accountTypes.push({
              value: "PERSONAL",
              text: "Conta Pessoal (CPF)",
              disabled: true,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loading = true;

    this.$store
      .dispatch("userRequest")
      .then((response) => {
        this.user = response;
        this.fetchBankAccount();

        if (
          this.user.company &&
          this.checkStatus(this.user.company.documents, "APPROVED").length
        ) {
          this.accountTypes.push({
            value: "BUSINESS",
            text: "Conta Empresarial (CNPJ)",
          });
        } else {
          this.accountTypes.push({
            value: "BUSINESS",
            text: "Conta Empresarial (CNPJ)",
            disabled: true,
          });
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.BankAccount-item {
  border-top: 1px #ededf0 solid;
  padding: 15px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.banco {
  // background: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.banco input {
  min-width: 270px;
}
.banco .search_bank {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: 30px;
  outline: none;
  cursor: pointer;
  margin-top: 30px;
  color: #333;
  font-weight: 600;
}
.search_bank img {
  margin-right: 15px;
}
.titulo-contas {
  font-weight: 600;
  font-size: 16px;
}
.header-table-contas {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}
.btn-novo {
  padding: 5px 10px;
  padding: 4px 8px;
  font-size: 13px;
  font-weight: 600;
  margin-right: 10px;
  border: none;
}
.btn-editar {
  // background: rgba(33, 51, 210, 0.1);
  background: transparent;
  color: #2a63ab;
}

@media screen and (max-width: 768px) {
  .banco {
    display: block;
  }
  .banco .search_bank {
    margin: 0;
    padding: 0;
    margin-top: 15px;
    justify-content: flex-end;
  }
}
.info-documento,
.sem-docs p,
.info-remove {
  margin: 0;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  line-height: 1.5;
}
.info-remove {
  margin-top: 15px;
}
.sem-docs {
  padding: 10px 0;
}
.sem-docs p {
  font-size: 14px;
}
.sem-docs p + p {
  margin-top: 7px;
}
.sem-docs button {
  margin: 20px 0;
}
.aguardando-analise {
  background: #00236310;
  padding: 20px;
  border-radius: 5px;
}
.aguardando-analise p {
  color: #2a63ab;
}
</style>
