<template>
  <div class="container">
    <BaseHeader title="Extrato Financeiro"> </BaseHeader>

    <div class="top-header">
      <div class="box-valores" data-anima="top">
        <div class="item-valor small-item" id="saldo-disponivel">
          <img src="@/assets/img/icons/money-free.svg" />
          <div class="item-money">
            <span>Saldo disponível</span>
            <p :class="{ visibled: !loading_money }" class="money">
              R$
              {{ available ? formatMoney(available) : "0,00" }}
            </p>
          </div>
        </div>

        <b-tooltip
          target="saldo-disponivel"
          title="Valor disponível para saque imediato."
        />
        <div class="item-valor small-item" id="saldo-block">
          <img src="@/assets/img/icons/money-block.svg" />
          <div class="item-money">
            <span>Saldo bloqueado</span>
            <p :class="{ visibled: !loading_money }" class="money">
              R$
              {{ block ? formatMoney(block) : "0,00" }}
            </p>
          </div>
        </div>

        <b-tooltip
          target="saldo-block"
          title="Valor bloqueado, ficará disponível para saque em breve."
        />

        <div id="saldo-antecipacao" class="item-valor">
          <img
            src="@/assets/img/icons/money-anticipation.svg"
            v-if="permite_antecipacao && statusAntecipation !== 'REQUESTED'"
          />
          <div
            class="item-money"
            v-if="permite_antecipacao && statusAntecipation !== 'REQUESTED'"
          >
            <span>Disponível para Antecipação</span>
            <p :class="{ visibled: !loading_money }" class="money">
              R$
              {{ antecipation ? formatMoney(antecipation) : "0,00" }}
            </p>
          </div>
        </div>

        <b-tooltip
          target="saldo-antecipacao"
          title="Valor disponível para antecipação imediata"
        />

        <div class="item-valor" id="saldo-carteira">
          <img src="@/assets/img/icons/money-wallet.svg" />
          <div class="item-money">
            <span>Minha carteira</span>
            <p :class="{ visibled: !loading_money }" class="money">
              R$
              {{ total ? formatMoney(total) : "0,00" }}
            </p>
          </div>
        </div>

        <b-tooltip
          target="saldo-carteira"
          title="Valor total da sua carteira."
        />
      </div>
      <BaseButton variant="secondary" @click="openModal" class="btn-sacar">
        Sacar Dinheiro
      </BaseButton>
    </div>

    <b-tabs
      nav-class="TabHeader"
      active-nav-item-class="TabHeader--active"
      :no-nav-style="true"
      @activate-tab="tabSelect"
      v-model="tabIndex"
    >
      <b-tab title="Transações" active>
        <b-container class="container-table">
          <div class="header-table">
            <div class="d-flex">
              <div class="btns-flex" @click="openFilters">
                <b-button class="btn-table" title="Filtros"
                  ><img src="@/assets/img/icons/filtro.svg"
                /></b-button>
                <span class="filter">Filtros</span>
              </div>
              <div class="ml-5 limpa-filtro" v-if="filter_antecipation">
                <span
                  >Estamos listando as transações ref. a antecipação selecionada
                  anteriormente. Se desejar listar todas as transações
                  <a href="#" @click.prevent="resetAntecipation">
                    clique aqui.</a
                  ></span
                >
              </div>
            </div>

            <div class="inputSearch">
              <img src="@/assets/img/icons/search.svg" class="Pointer search" />
              <input
                type="text"
                placeholder="Nome do Produto"
                v-on:input="debounce"
                v-model="search"
                class="input-busca"
              />
            </div>
          </div>

          <!-- HEADER -->
          <b-row class="Table-header mb-1">
            <b-col cols="2" class="d-none d-md-block"> Data </b-col>

            <b-col cols="5" md="2"> Tipo </b-col>

            <b-col cols="5" md="3" class="d-none d-md-block"> Produto </b-col>

            <b-col cols="4" md="2" class=""> Valor </b-col>

            <b-col cols="2" class="d-none d-md-block"> Disponível </b-col>

            <b-col cols="3" md="1" class=""> Visualizar </b-col>
          </b-row>
          <!-- END HEADER -->

          <!-- BODY -->
          <template v-if="!loading && statements.length > 0">
            <b-row
              class="Table-body"
              v-for="item in statements"
              :key="item.id"
              :class="{
                'is-green': item.type == 'SALE',
                'is-light-blue':
                  item.type == 'WITHDRAW' ||
                  item.type == 'WITHDRAW_FEE' ||
                  item.type == 'ANTECIPATION' ||
                  item.type == 'ANTECIPATION_FEE' ||
                  item.type == 'ANTECIPATION_BALANCE' ||
                  item.type == 'ANTECIPATION_REMAINING',

                'is-red': item.type == 'CHARGEBACK' || item.type == 'REFUNDED',
              }"
            >
              <b-col cols="2" class="d-none d-md-block">
                {{ item.created_at | datetime }}
              </b-col>

              <b-col cols="5" md="2">
                <span v-if="item.type == 'SALE'"
                  >Venda
                  <a
                    href="#"
                    class="link-venda"
                    @click.prevent="openSale(item.sale_id)"
                  >
                    ({{ item.sale_id }})</a
                  ></span
                >
                <span v-if="item.type == 'CHARGEBACK'">Chargeback</span>
                <span v-if="item.type == 'REFUNDED'"
                  >Reembolso
                  <a
                    href="#"
                    class="link-venda"
                    @click.prevent="openSale(item.sale_id)"
                  >
                    ({{ item.sale_id }})</a
                  ></span
                >
                <span v-if="item.type == 'WITHDRAW'"
                  >Saque
                  <a
                    href="#"
                    class="link-venda"
                    @click.prevent="openPanel(item.id)"
                  >
                    ({{ item.withdraw_id }})</a
                  ></span
                >
                <span v-if="item.type == 'WITHDRAW_FEE'"
                  >Taxa de saque
                  <a
                    href="#"
                    class="link-venda"
                    @click.prevent="openPanel(item.id)"
                  >
                    ({{ item.withdraw_id }})</a
                  >
                </span>
                <span v-if="item.type == 'ANTECIPATION_FEE'"
                  >Taxa de Antecipação
                  <a
                    href="#"
                    class="link-venda"
                    @click.prevent="openPanel(item.antecipation_group_id, true)"
                  >
                    ({{ item.antecipation_group_id }})</a
                  >
                </span>
                <span v-if="item.type == 'ANTECIPATION_BALANCE'"
                  >Saldo da Antecipação
                  <a
                    href="#"
                    class="link-venda"
                    @click.prevent="openPanel(item.antecipation_group_id, true)"
                  >
                    ({{ item.antecipation_group_id }})</a
                  >
                </span>
                <span v-if="item.type == 'ANTECIPATION_REMAINING'"
                  >Antecipação Restante
                </span>
                <span v-if="item.type == 'ANTECIPATION'"
                  >Antecipação
                  <a
                    href="#"
                    class="link-venda"
                    @click.prevent="openPanel(item.antecipation_group_id, true)"
                  >
                    ({{ item.antecipation_group_id }})</a
                  ></span
                >
              </b-col>

              <b-col cols="5" md="3" class="d-none d-md-block">
                <template v-if="!item.proposal">
                  <div
                    class="Table-body-title"
                    v-if="
                      item.type != 'WITHDRAW' && item.type != 'WITHDRAW_FEE'
                    "
                  >
                    {{
                      item.product
                        ? item.product.name.length > maxLength
                          ? item.product.name.substr(0, maxLength) + ".."
                          : item.product.name
                        : ""
                    }}
                  </div>
                </template>
                <template v-else>
                  <div
                    class="Table-body-title"
                    v-if="
                      item.type != 'WITHDRAW' && item.type != 'WITHDRAW_FEE'
                    "
                  >
                    {{
                      item.proposal.name.length > maxLength
                        ? item.proposal.name.substr(0, maxLength) + ".."
                        : item.proposal.name
                    }}
                  </div>
                </template>
              </b-col>

              <b-col cols="4" md="2" class="">
                R$ {{ formatMoney(item.balance, 2, ",") }}
              </b-col>

              <b-col cols="2" class="d-none d-md-block">
                <span
                  v-if="item.type != 'WITHDRAW' && item.type != 'WITHDRAW_FEE'"
                  >{{ item.available_date | date }}</span
                >
              </b-col>

              <b-col cols="3" md="1">
                <div class="Table-body-action" @click="openPanel(item.id)">
                  <img src="@/assets/img/icons/eye.svg" />
                </div>
              </b-col>
            </b-row>
          </template>

          <Paginate
            v-if="statements.length > 0"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
          />

          <div
            v-if="loading && statements.length == 0"
            class="
              Table-body
              py-4
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <b-spinner variant="dark" label="Spinning"></b-spinner>
          </div>

          <b-row
            v-if="!loading && statements.length == 0"
            class="Table-body justify-content-center"
          >
            <p class="nao-encontrado">Nenhum extrato encontrado</p>
          </b-row>
          <!-- END BODY -->
        </b-container>
      </b-tab>
      <b-tab title="Saques">
        <b-container fluid>
          <!-- HEADER -->
          <b-row class="Table-header my-1">
            <b-col cols="2" class="d-none d-md-block"> Data </b-col>

            <b-col cols="6" md="2" class=""> Valor </b-col>

            <b-col cols="3" class="d-none d-md-block"> Conta </b-col>

            <b-col cols="2" class="d-none d-md-block"> Observação </b-col>

            <b-col cols="6" md="3" class=""> Status </b-col>
          </b-row>
          <!-- END HEADER -->

          <!-- BODY -->
          <template v-if="!loading && withdrawals.length > 0">
            <b-row
              class="Table-body"
              v-for="item in withdrawals"
              :key="item.id"
            >
              <b-col cols="2" class="d-none d-md-block">
                {{ item.created_at | datetime }}
              </b-col>

              <b-col cols="6" md="2" class="Table-body-price">
                R$ {{ formatMoney(item.amount, 2, ",") }}
              </b-col>

              <b-col cols="3" class="d-none d-md-block">
                {{ item.account.account }} - {{ item.account.bank_name }}
              </b-col>

              <b-col cols="2" class="Table-body-price d-none d-md-block">
                {{ item.observation }}
              </b-col>

              <b-col cols="6" md="3" class="">
                <span class="icon-blue" v-if="item.status == 'REQUESTED'"
                  >Requisitado</span
                >
                <span class="icon-grenn" v-if="item.status == 'ACCEPTED'"
                  >Transferido</span
                >
                <span class="icon-red" v-if="item.status == 'DENIED'"
                  >Recusado</span
                >
                <span class="icon-warning" v-if="item.status == 'REFUNDED'"
                  >Reembolsado</span
                >
              </b-col>
            </b-row>
          </template>
          <!-- END BODY -->

          <div
            v-if="loading && withdrawals.length == 0"
            class="
              Table-body
              py-4
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <b-spinner variant="dark" label="Spinning"></b-spinner>
          </div>

          <b-row
            v-if="!loading && withdrawals.length == 0"
            class="Table-body justify-content-center"
          >
            <p class="nao-encontrado">
              Nenhuma solicitação de saque foi encontrada
            </p>
          </b-row>
          <!-- END BODY -->
          <Paginate
            v-if="withdrawals.length > 0"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
          />
        </b-container>
      </b-tab>

      <b-tab title="Antecipações" v-if="permite_antecipacao">
        <b-container class="container-table mb-5">
          <!-- HEADER -->
          <b-row class="Table-header mb-1">
            <b-col md="1" class="d-none d-md-block"> #ID</b-col>

            <b-col md="2" class="d-none d-md-block"> Dt. Criação </b-col>

            <b-col md="2" class="d-none d-md-block"> Vl. Bruto </b-col>

            <b-col cols="4" md="1" class="d-none d-md-block"> Taxa </b-col>

            <b-col cols="5" md="2"> Vl. Antecipado </b-col>
            <b-col cols="4" md="2"> Dt. Processada</b-col>
            <b-col cols="3" md="1"> Visualizar</b-col>
          </b-row>
          <!-- END HEADER -->

          <!-- BODY -->
          <template v-if="!loading && antecipationResponse.length > 0">
            <b-row
              class="Table-body"
              v-for="item in antecipationResponse"
              :key="item.id"
            >
              <b-col md="1" class="d-none d-md-block"> #{{ item.id }} </b-col>

              <b-col md="2" class="d-none d-md-block">
                {{ item.created_at | datetime }}
              </b-col>

              <b-col md="2" class="d-none d-md-block">
                R$ {{ formatMoney(item.amount) }}
              </b-col>

              <b-col cols="4" md="1" class="d-none d-md-block">
                R$ {{ formatMoney(item.fee) }}
              </b-col>

              <b-col cols="5" md="2"> R$ {{ formatMoney(item.total) }} </b-col>
              <b-col cols="4" md="2">
                {{ item.updated_at | datetime }}
              </b-col>

              <b-col cols="3" md="1">
                <div
                  class="Table-body-action"
                  @click="openAntecipation(item.id)"
                >
                  <img src="@/assets/img/icons/eye.svg" />
                </div>
              </b-col>
            </b-row>
          </template>

          <!-- <Paginate
            v-if="antecipationResponse.length > 0"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
          /> -->

          <div
            v-if="loading && antecipationResponse.length == 0"
            class="
              Table-body
              py-4
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <b-spinner variant="dark" label="Spinning"></b-spinner>
          </div>

          <b-row
            v-if="!loading && antecipationResponse.length == 0"
            class="Table-body justify-content-center"
          >
            <p class="nao-encontrado">Nenhuma antecipação encontrada</p>
          </b-row>
          <!-- END BODY -->
        </b-container>
      </b-tab>
    </b-tabs>

    <!-- modals -->
    <withdraw
      :available="available"
      :antecipation="antecipation"
      :total="total"
      :permite_antecipacao="permite_antecipacao"
      :antecipation_fee="antecipation_fee"
      :status_antecipation="statusAntecipation"
      :last_antecipation="LastAntecipation"
      @close="fetchStatements"
      @pending="fetchLastAntecipation"
    />
    <filters @filter="fetchStatements" />
    <ModalBankAccount />
    <DocumentNumber @close="openModal" />
    <RightPreview ref="statementsPreview" />
    <!--<CallEvaluation /> -->
  </div>
</template>
<script>
import Paginate from "@/components/shared/Paginate";
import Money from "@/mixins/money";
import Withdraw from "@/components/Seller/Statements/modals/withdraw";
import DocumentNumber from "@/components/Seller/Statements/modals/documentNumber";
import RightPreview from "@/components/Seller/Statements/rightPreview";
import Filters from "@/components/Seller/Statements/modals/filters";
import ModalBankAccount from "@/components/Seller/Statements/modals/bankAccount";

import AccountStatementsService from "@/services/resources/AccountStatementsService";
import WithdrawService from "@/services/resources/WithdrawService";
import DashboardService from "@/services/resources/DashboardService";
import UserSettingsService from "@/services/resources/UserSettingsService";
import AntecipationService from "@/services/resources/AntecipationService";

const serviceStatement = AccountStatementsService.build();
const serviceWithdraw = WithdrawService.build();
const serviceDashboard = DashboardService.build();
const serviceUserSettings = UserSettingsService.build();
const serviceAntecipation = AntecipationService.build();

import Cookies from "js-cookie";

import _ from "lodash";

export default {
  name: "Extrato",
  components: {
    Paginate,
    Withdraw,
    RightPreview,
    Filters,
    ModalBankAccount,
    DocumentNumber,
  },
  data() {
    return {
      loading_money: false,
      loading: false,
      tabIndex: 0,
      statements: [],
      antecipationResponse: [],
      filter_antecipation: false,
      antecipation_id: -1,
      statement_id: -1,
      withdrawals: [],
      total: 0,
      available: 0,
      block: 0,
      antecipation: 0,
      search: "",
      statusAntecipation: null,
      LastAntecipation: null,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 15,
      },
      permite_antecipacao: false,
      antecipation_fee: 0,
    };
  },
  mixins: [Money],
  computed: {
    user() {
      return this.$store.getters.getProfile;
    },
    userId() {
      return this.$store.getters.getProfile.id;
    },
    maxLength() {
      return this.$store.getters["sidebarState"] ? 20 : 30;
    },
  },
  created() {
    if (this.$store.getters.setPanel) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    resetAntecipation() {
      this.filter_antecipation = false;
      this.fetchStatements();
    },
    openAntecipation(id_antecipation) {
      this.filter_antecipation = true;
      const filter = {
        antecipation_group_id: id_antecipation,
      };
      this.tabSelect(0, filter);
    },
    fetchAntecipations() {
      this.loading = true;
      this.antecipationResponse = [];
      // let data = {
      //   page: this.pagination.currentPage,
      //   per_page: this.pagination.perPage,
      // };
      // this.pagination.totalPages = 1;

      serviceAntecipation
        .search()
        .then((response) => {
          this.antecipationResponse = response;
          // this.pagination.totalPages = response.last_page;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    openSale(id) {
      this.$router.push(`/vendas/?s_id=${id}`);
    },
    fetchUserSettings() {
      let data = {
        id: this.userId || JSON.parse(localStorage.user).id,
      };

      serviceUserSettings.read(data).then((response) => {
        response.forEach((item) => {
          if (item.key == "ANTECIPATION" && item.value == "1") {
            this.permite_antecipacao = true;
          }
          if (item.key == "ANTECIPATION_FEE") {
            this.antecipation_fee = +item.value;
          }
        });
      });
    },
    debounce: _.debounce(function() {
      this.fetchStatements({}, this.search);
    }, 500),

    tabSelect(newTabIndex, filter = {}) {
      // if (this.tabIndex != newTabIndex) {
      this.tabIndex = newTabIndex;
      switch (newTabIndex) {
        case 0: {
          this.fetchStatements(filter);
          break;
        }
        case 1: {
          this.fetchWithdrawals();
          break;
        }
        case 2: {
          this.fetchAntecipations();
          break;
        }
      }
      // }
    },
    openModal() {
      this.$bvModal.show("withdraw-request");
    },
    // openPanel(statement_id) {
    //   this.statement_id = statement_id;
    // },
    openPanel(id, antecipation) {
      if (!antecipation) {
        this.statement_id = id;
        this.$router
          .push({ query: { ex_id: this.statement_id } })
          .catch(() => {});
      } else {
        this.antecipation_id = id;
        this.$router
          .push({ query: { antecipation_id: this.antecipation_id } })
          .catch(() => {});
      }

      setTimeout(() => {
        this.$refs.statementsPreview.openPanel(id, antecipation);
        this.loading = false;
      });
    },
    openFilters() {
      this.$bvModal.show("account-statements-filters");
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.tabSelect(this.tabIndex);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.tabSelect(this.tabIndex);
    },
    fetchStatements(filters = {}, search = "") {
      this.fetchLastAntecipation();

      this.loading = true;
      this.loading_money = true;
      this.statements = [];

      var data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
      };
      this.pagination.totalPages = 1;

      if (search.length > 0) {
        data.search = search;
      } else {
        if (typeof filters.date_in != "undefined" && filters.date_in != null)
          data.date_in = filters.date_in;
        if (typeof filters.date_out != "undefined" && filters.date_out != null)
          data.date_out = filters.date_out;

        if (typeof filters.antecipation_group_id != "undefined")
          data.antecipation_group_id = filters.antecipation_group_id;
      }

      serviceStatement
        .search(data)
        .then((response) => {
          this.loading = false;
          this.statements = response.data;
          this.pagination.totalPages = response.last_page;
          this.fetchReports();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    fetchReports() {
      this.loading_money = true;
      let data = {
        types: ["statements"],
      };

      serviceDashboard
        .search(data)
        .then((response) => {
          this.available = response.statementsAvailable;
          this.total = response.statementsTotal;
          this.block = response.statementsBlock;
          this.antecipation = response.statementsCanBeAntecipated;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading_money = false;
        });
    },
    fetchWithdrawals() {
      var data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
      };
      this.pagination.totalPages = 1;

      serviceWithdraw
        .search(data)
        .then((response) => {
          this.loading = false;
          this.withdrawals = response.data;
          this.pagination.totalPages = response.last_page;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    fetchLastAntecipation() {
      serviceAntecipation
        .read("/request/info")
        .then((response) => {
          console.log(response);

          if (response.automatic_approve === "1") {
            response.last_antecipation.status = "AUTOMATICALLY_APPROVED";
          }

          this.statusAntecipation = response.last_antecipation.status;
          this.LastAntecipation = response.last_antecipation;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  mounted() {
    this.fetchStatements();
    this.fetchUserSettings();

    if (this.$route.query.withdraw == "true") {
      this.openModal();
    }

    // abrindo venda unica
    if (typeof this.$route.query.ex_id != "undefined") {
      this.statement_id = this.$route.query.ex_id;
      setTimeout(() => {
        this.$refs.statementsPreview.openPanel(this.statement_id);
        this.loading = false;
      }, 1000);
    }

    if (typeof this.$route.query.antecipation_id != "undefined") {
      this.antecipation_id = this.$route.query.antecipation_id;
      setTimeout(() => {
        this.$refs.statementsPreview.openPanel(this.antecipation_id, true);
        this.loading = false;
      }, 1000);
    }
  },
};
</script>
<style scoped>
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  /* padding-top: 15px; */
}
.btns-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .header-table {
    display: grid;
    grid-template-columns: 50px 1fr;
  }
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}

.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: rgba(0, 33, 99, 0.1);
}
.btn-table:first-child:active {
  background: rgba(0, 33, 99, 0.1);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.input-busca {
  width: 350px;
  height: 60px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

@media screen and (max-width: 768px) {
  .input-busca {
    width: 100%;
  }
}
.search {
  position: absolute;
  left: 22px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 1;
}
.inputSearch {
  position: relative;
}
/* box valores */
.box-valores {
  display: flex;
  /* width: 450px; */
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
}
.box-valores + .box-valores {
  margin-top: 15px;
}

.top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px auto;
}
@media screen and (max-width: 768px) {
  .top-header {
    /* flex-direction: column; */
    display: block;
    margin: 40px 0;
  }
  .top-header .btn-sacar {
    margin-top: 20px;
  }
}
.item-valor {
  display: flex;
  align-items: center;
  gap: 15px;
}
.item-valor span {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
}
.item-valor p {
  font-size: 16px;
  font-weight: 600;
  margin: 3px 0;
}
.filter {
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}
.money {
  background: rgba(0, 0, 0, 0.03);
  width: 100%;
  border-radius: 5px;
  transition: 0.3s;
  color: transparent;
}
.money.visibled {
  background: transparent;
  color: #333;
}
@media screen and (max-width: 768px) {
  .filter {
    display: none;
  }
  .Table-body {
    padding: 15px;
  }

  header {
    display: block;
  }
  .box-valores {
    /* justify-content: flex-start; */
    display: block;
    margin-top: 20px;
  }
  .item-valor {
    text-align: left;
    margin: 0 !important;
    max-width: 270px;
  }
  .item-valor + .item-valor {
    margin-top: 15px !important;
  }
  .item-valor p {
    font-size: 18px;
  }
}
.link-venda {
  font-size: 12px;
  color: #2a63ab;
  font-weight: 600;
}
.limpa-filtro {
  font-size: 13px;
  max-width: 60%;
}
.limpa-filtro span {
  color: #81858e;
}
.limpa-filtro a {
  color: #2a63ab;
}
</style>
