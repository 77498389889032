<template>
  <div>
    <BaseModal
      name="withdraw-request"
      size="md"
      title="Solicitação de saque"
      @shown="openModal"
      @hidden="close"
    >
      <!-- <div class="d-flex align-items-center justify-content-between"> -->
      <div class="d-flex justify-content-center" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>

      <div
        v-if="
          !loading && accounts.length && status_validation.status == 'APPROVED'
        "
      >
        <div class="item-top justify-content-between">
          <div class="item-top">
            <img src="@/assets/img/icons/money-free.svg" />
            <div class="money">
              <span>Saldo disponível</span>
              <p>
                R$
                {{ valor_disponivel ? formatMoney(valor_disponivel) : "0,00" }}
              </p>
            </div>
          </div>
          <!-- <BaseButton variant="primary" class="btn-saldo"
            >Sacar tudo</BaseButton
          > -->
        </div>

        <div
          class="item-top item-antecipa"
          v-if="permite_antecipacao && status_antecipation !== 'REQUESTED'"
        >
          <div class="item-top">
            <img src="@/assets/img/icons/money-anticipation.svg" />
            <div class="money">
              <span>Disponível para Antecipação</span>
              <p>
                R$
                {{
                  valor_antecipavel ? formatMoney(valor_antecipavel) : "0,00"
                }}
              </p>
            </div>
          </div>

          <div id="quero-antecipar">
            <BaseButton
              variant="info3"
              class="btn-saldo"
              @click="openAntecipation"
              v-if="permite_antecipacao"
              :disabled="!antecipation"
              >⚡ Quero antecipar</BaseButton
            >
          </div>
          <b-tooltip
            v-if="!antecipation"
            target="quero-antecipar"
            title="Você não possui valores para antecipar"
          />
        </div>

        <div class="text-info mt-3">
          <div
            class="aprovacao btn-2 mt-2 mb-3"
            v-if="status_antecipation === 'REQUESTED'"
          >
            <img src="@/assets/img/icons/info-prod.svg" />
            Você tem uma antecipação em aprovação no valor de R$
            {{ formatMoney(last_antecipation.amount) }}. dentro de poucas horas
            você receberá um e-mail, e em caso de aprovação, o dinheiro ficará
            disponível para saque imediato.
          </div>
          <div
            class="aprovacao DISAPPROVED btn-2 mt-2 mb-3"
            v-if="status_antecipation === 'REFUSED'"
          >
            <img src="@/assets/img/icons/info-prod-reprovado.svg" />
            Sua ultima antecipação foi recusada, foram enviados mais detalhes no
            seu E-mail.
          </div>
        </div>

        <div class="mb-3 mt-4">
          <h5 class="topico">Qual o valor do saque?</h5>

          <b-form novalidate>
            <b-form-group label="" label-for="amount">
              <money
                id="amount"
                v-model="amount"
                v-bind="money"
                v-validate="'required'"
                autocomplete="off"
              ></money>
              <b-form-invalid-feedback :state="!amount_error">
                Preencha o valor corretamente
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
        </div>

        <h5 class="topico">Para qual conta você quer transferir?</h5>
        <div class="mb-4 d-block d-md-flex align-items-center">
          <b-form novalidate>
            <b-form-group label="" label-for="bank_account">
              <b-form-select
                class="select-contas-bancarias"
                v-model="bank_account"
                name="bank_account"
                :options="accounts"
                v-validate="'required'"
                @change="validaPre"
              ></b-form-select>
              <b-form-invalid-feedback :state="!errors.has('bank_account')">
                Por favor, selecione uma conta bancária
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
          <b-tooltip target="btn-conta" title="Adicionar conta bancária" />
          <BaseButton
            id="btn-conta"
            variant="link-info"
            class="btn-conta-corrente"
            @click="openBanks"
          >
            <img src="@/assets/img/icons/card-outline.svg" />
            <span class="d-md-none d-block ml-2">Adicionar conta</span>
          </BaseButton>
        </div>
      </div>

      <div
        v-if="!accounts.length && status_validation.status == 'APPROVED'"
        class="sem-contas"
      >
        <p>Você não possui contas bancárias cadastradas.</p>
        <BaseButton variant="info3" class="mt-3" v-b-modal.bank-account>
          Cadastrar conta bancária agora
        </BaseButton>
      </div>

      <div
        class="detalhes-saque"
        v-if="!pre_infos && status_validation.status == 'APPROVED'"
        data-anima="top"
      >
        <h5 class="topico topico_detalhes">Você está sacando</h5>
        <!-- <h4>R$ {{ formatMoney(amount) }} </h4> -->

        <div class="item-detalhe">
          <span>Dados da conta</span>
          <div class="info-detalhe">
            <p>CPF/CNPJ</p>
            <p class="value-info">{{ conta_selecionada.cpf_cnpj }}</p>
          </div>
          <div class="info-detalhe">
            <p>Instituição</p>
            <p class="value-info">{{ conta_selecionada.bank_name }}</p>
          </div>
          <div class="info-detalhe">
            <p>Agência</p>
            <p class="value-info">{{ conta_selecionada.agency }}</p>
          </div>
          <div class="info-detalhe">
            <p>
              Conta
              {{
                conta_selecionada.type == "CURRENT" ? "Corrente" : "Poupança"
              }}
            </p>
            <p class="value-info">
              {{ conta_selecionada.account }}-{{ conta_selecionada.account_dv }}
            </p>
          </div>
        </div>

        <div class="item-detalhe" v-if="amount">
          <span>Transferência</span>
          <div class="info-detalhe">
            <p>Valor solicitado</p>
            <p class="value-info">R$ {{ formatMoney(amount) }}</p>
          </div>
          <div class="info-detalhe">
            <p>Taxa de Saque</p>
            <!-- <p class="value-info taxa-info">- R$ 4,99</p> -->
            <p class="value-info taxa-info">
              - R$ {{ formatMoney(taxa_saque) }}
            </p>
          </div>
          <div class="info-detalhe">
            <p>Será transferido</p>
            <p class="value-info">R$ {{ formatMoney(amount - taxa_saque) }}</p>
          </div>
        </div>
        <div class="item-detalhe" v-else>
          <span>___ Preencha corretamente o valor que deseja sacar </span>
        </div>
      </div>

      <div
        v-if="!loading && status_validation.status != 'APPROVED'"
        class="verificao"
      >
        <div v-if="status_validation.status == 'REQUESTED'">
          <p>
            Sua verificação de identidade já foi enviada para nossa equipe e
            está em processo de análise.
          </p>
          <p>
            Aguarde, em breve receberá o retorno com o resultado da análise.
          </p>
        </div>
        <div v-else>
          <p>Vimos que sua verificação de identidade ainda não foi feita.</p>
          <p>
            Para adicionar uma conta bancária, primeiro é necessário que faça a
            verificação de identidade.
          </p>
          <BaseButton variant="info3" @click="initVerification"
            >Iniciar a verificação de identidade</BaseButton
          >
        </div>
      </div>

      <template v-slot:footer="{ cancel }">
        <BaseButton
          variant="secondary_outline"
          class="mr-4"
          @click="cancel"
          :disabled="loading"
        >
          Cancelar
        </BaseButton>
        <div id="btn-sacar">
          <BaseButton
            variant="link-info"
            @click="onSubmit"
            :disabled="loading || pre_infos || amount < 100"
          >
            Solicitar saque
          </BaseButton>
        </div>
        <b-tooltip
          v-if="amount < 100"
          target="btn-sacar"
          title="Valor minímo para saque é de R$ 100,00"
        />

        <b-tooltip
          v-if="pre_infos && amount > 99"
          target="btn-sacar"
          title="Selecione a conta para transferência"
        />
      </template>
    </BaseModal>

    <Antecipation
      :antecipation="valor_antecipavel"
      @close="fetchReports"
      @pending="setPending"
      :antecipation_fee="antecipation_fee"
      :antecipation_status="status_antecipation"
    />
    <ModalValidation @close="getStatusValidation" />
  </div>
</template>
<script>
import { EventBus } from "@/main.js";

import WithdrawService from "@/services/resources/WithdrawService";
import BankAccountService from "@/services/resources/BankAccountService";

import { Money } from "v-money";
import FormatMoney from "@/mixins/money";

const serviceWithdraw = WithdrawService.build();
const serviceBankAccount = BankAccountService.build();

import DashboardService from "@/services/resources/DashboardService";
const serviceStatement = DashboardService.build();

import GlobalSettings from "@/services/resources/GlobalSettings";
const serviceGlobalSettings = GlobalSettings.build();

import Antecipation from "./antecipation";

import ValidationService from "@/services/resources/ValidationService";
const serviceValidation = ValidationService.build();

export default {
  props: {
    permite_antecipacao: {},
    status_antecipation: {},
    last_antecipation: {},
    antecipation_fee: {
      type: Number,
      default: 0,
    },
    available: {
      type: Number,
      default: 0,
    },
    antecipation: {
      type: Number,
      default: 0,
    },
  },
  mixins: [FormatMoney],

  data() {
    return {
      status_validation: {
        status: "",
        observation: "",
      },
      pre_infos: true,
      valor_disponivel: 0,
      valor_antecipavel: 0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },

      amount: 0,
      amount_error: false,
      bank_account: "",
      accounts: [
        {
          value: "",
          text: "Selecione uma conta",
        },
      ],
      contas: [
        {
          _infos: {
            cpf_cnpj: "",
            bank_name: "",
            agency: "",
            account: "",
            type: "",
          },
        },
      ],
      conta_selecionada: {
        cpf_cnpj: "",
        bank_name: "",
        agency: "",
        account: "",
        type: "",
        account_dv: "",
      },
      loading: false,
      submit: false,
    };
  },
  components: {
    Money,
    Antecipation,
  },
  computed: {
    userId() {
      return this.$store.getters.getProfile.id;
    },
  },

  methods: {
    setPending() {
      this.status_antecipation = "REQUESTED";
      this.$emit("pending");
    },
    close() {
      this.$emit("close");
    },
    fetchGlobalSettings() {
      let data = {
        keys: "SELLER_WITHDRAW_FEE",
      };
      serviceGlobalSettings.search(data).then((response) => {
        response.forEach((item) => {
          if (item.key == "SELLER_WITHDRAW_FEE") {
            this.taxa_saque = +item.value;
          }
        });
      });
    },
    initVerification() {
      this.$bvModal.show("identity-validation");
    },
    getStatusValidation() {
      this.loading = true;
      let data = {
        id: this.userId,
      };
      serviceValidation
        .read(data)
        .then((response) => {
          this.status_validation = response;
          if (!this.status_validation.status) {
            this.status_validation = {
              status: "INITIATED",
              observation: "",
            };
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchReports() {
      this.loading = true;
      let data = {
        types: ["statements"],
      };

      serviceStatement
        .search(data)
        .then((response) => {
          this.valor_disponivel = response.statementsAvailable;
          this.valor_antecipavel = response.statementsCanBeAntecipated;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    openAntecipation() {
      this.$bvModal.show("antecipation");
    },
    openBanks() {
      this.$bvModal.show("bank-account");
    },
    whatDoc(doc) {
      if (doc.length > 14) {
        return "cnpj";
      } else return "cpf";
    },
    validaPre() {
      if (this.bank_account) {
        const conta = this.contas.find((item) => {
          if (item.id == this.bank_account) {
            return item;
          }
        });

        this.pre_infos = false;
        this.conta_selecionada = {
          cpf_cnpj: conta.cpf_cnpj,
          bank_name: conta.bank_name,
          agency: conta.agency,
          account: conta.account,
          account_dv: conta.account_dv,
          type: conta.type,
        };
      } else this.pre_infos = true;
    },
    roundValue(num, places) {
      return +parseFloat(num).toFixed(places);
    },
    onSubmit() {
      const typeDoc = this.whatDoc(this.conta_selecionada.cpf_cnpj);
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (
            !this.amount ||
            this.amount > this.roundValue(this.valor_disponivel, 2)
          ) {
            this.amount_error = true;
            this.$bvToast.toast(
              "Preencha o valor corretamente e tente novamente",
              {
                title: "Saque",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            return;
          }

          if (typeDoc == "cpf" && this.amount > 2000) {
            this.$bvToast.toast(
              "Conta de Pessoa Física é autorizado sacar apenas R$ 2.000,00 por mês ",
              {
                title: "Saque",
                variant: "info",
                autoHideDelay: 9000,
                appendToast: true,
              }
            );

            this.amount_error = true;
            return;
          }

          this.loading = true;
          this.amount_error = false;

          let data = {
            amount: this.amount,
            account_id: this.bank_account,
          };

          serviceWithdraw
            .create(data)
            .then(() => {
              this.amount = 0;
              this.bank_account = "";
              this.pre_infos = true;

              this.$bvToast.toast(
                "Sua solicitação de saque foi feita com sucesso!",
                {
                  title: "Saque",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
              this.$emit("close");
              this.$bvModal.hide("withdraw-request");
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    openModal() {
      this.fetchAccounts();
      this.valor_disponivel = this.available;
      this.valor_antecipavel = this.antecipation;
    },
    fetchAccounts() {
      this.loading = true;
      this.accounts = [{ value: "", text: "Selecione uma conta" }];

      serviceBankAccount
        .search()
        .then((response) => {
          response.forEach((element) => {
            this.accounts.push({
              value: element.id,
              text: element.account + " - " + element.bank_name,
            });
            this.contas.push({
              id: element.id,
              cpf_cnpj: element.cpf_cnpj,
              bank_name: element.bank_name,
              agency: element.agency,
              account: element.account,
              account_dv: element.account_dv,
              type: element.type,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    EventBus.$on("updateBanks", () => {
      this.fetchAccounts();
    });

    this.getStatusValidation();

    this.fetchGlobalSettings();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/variables.scss";

.amount-available {
  font-size: 34px;
  color: $base-color;
  font-weight: 600;
}

.btn-green {
  display: inline-block;
  border-radius: 5px;
  padding: 7px 20px;
  border: 1px $base-color solid;
  width: auto;
  color: #fff;
  background: $base-color;
  cursor: pointer;

  &:hover {
    background: #fff;
    color: $base-color;
    border: 1px $base-color solid;
  }
}
.item-valor {
  display: block;
  padding: 15px 0;
}
.item-valor + .item-valor {
  border-top: 1px solid #ededf0;
}
.item-valor span {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
}
.item-valor p {
  font-size: 28px;
  font-weight: 600;
  margin: 10px 0;
}
@media screen and (max-width: 768px) {
  .item-valor p {
    font-size: 13px;
  }
}
.saldo-livre {
  color: #2a63ab;
}
.item-valor p.taxa {
  font-size: 18px;
}
.btn-conta-corrente {
  display: flex;
  align-items: center;
  color: #333;
  background: #f7f7f7;
  font-weight: 600;
  font-size: 14px;
  padding: 0 20px !important;
  margin-left: 15px;
  margin-top: -15px;
}
@media screen and (max-width: 768px) {
  .btn-conta-corrente {
    margin-left: 0;
    margin-top: 0px;
    font-size: 13px;
  }
}
.btn-conta-corrente img {
  filter: brightness(1) invert(1);
  min-width: 18px;
}
.select-contas-bancarias {
  width: 350px;
  max-width: 100%;
}

// novos saque
.topico {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 15px;
}

.saldo-info {
  font-size: 13px;
  color: #81858e;
  margin-bottom: 20px;
}
.saldo-info span {
  font-weight: 600;
  color: #2a63ab;
}

.text-info .info {
  font-size: 13px;
  color: #2a63ab;
  margin-bottom: 20px;
}

.text-info .error {
  font-size: 13px;
  color: #ff0c37;
  margin-bottom: 20px;
}

.detalhes-saque {
}
.detalhes-saque .item-detalhe {
  // background: red;
  padding: 15px 10px;
}
.detalhes-saque .item-detalhe + .item-detalhe {
  margin-top: 10px;
  border-top: 1px solid #ededf0;
}
.detalhes-saque .item-detalhe span {
  font-size: 12px;
  color: #2a63ab;
  margin-bottom: 5px;
  display: block;
}
.detalhes-saque .item-detalhe .info-detalhe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}
.detalhes-saque .item-detalhe .info-detalhe + .info-detalhe {
  margin-top: 5px;
}
.detalhes-saque .item-detalhe .info-detalhe p {
  color: #81858e;
}
.detalhes-saque .item-detalhe .info-detalhe p.value-info {
  color: #333;
}

.topico_detalhes {
  margin-left: 10px;
  margin-bottom: 0;
}
.taxa-info {
  color: #ff0c37 !important;
}

.sem-contas {
  text-align: center;
  padding: 15px;
}
.sem-contas p {
  color: #81858e;
  font-size: 14px;
}

.item-top {
  display: flex;
  align-items: center;
  gap: 15px;
}
.item-top img {
  max-width: 35px;
}
.item-top + .item-top {
  margin-top: 20px;
}
.item-top span {
  font-size: 12px;
  font-weight: normal;
  color: #81858e;
}
.item-top p {
  font-size: 14px;
  font-weight: 600;
  // margin: 3px 0;
}

.item-top p.visibled {
  background: transparent;
  color: #333;
}
.item-antecipa {
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .item-antecipa {
    display: block;
  }
  .item-antecipa #quero-antecipar {
    margin-top: 15px;
  }
}
.btn-saldo {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.verificao p {
  color: #81858e;
  font-size: 14px;
}
.verificao button {
  margin-top: 30px;
}

.btn-2 {
  color: #2a63ab;
  background: rgba(33, 51, 210, 0.1);
  font-size: 12px;
  font-weight: 500;
  padding: 10px 15px;
  border-radius: 5px;
}
.aprovacao {
  display: flex;
  align-items: flex-start;
}
.aprovacao img {
  margin-right: 10px;
}
.DISAPPROVED {
  background: rgba(255, 12, 55, 0.1);
  color: #ff0c37;
}
.APPROVED {
  background: rgba(0, 33, 99, 0.1);
  color: #002363;
}
</style>
