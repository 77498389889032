<template>
  <div>
    <slot name="button" :openPanel="openPanel"> </slot>
    <b-sidebar
      id="sidebar-account-statements"
      width="450px"
      backdrop-variant="dark"
      backdrop
      right
      shadow
      v-model="visible"
      @hidden="resetUrl"
    >
      <b-container fluid class="container-sidebar">
        <b-row class="panel" v-if="!loading">
          <b-col v-if="!has_antecipation">
            <b-overlay :show="loading">
              <b-row class="item-dados">
                <b-col class="sumary">
                  <p
                    data-anima="bottom"
                    class="dados-campo amount-available"
                    :class="{ mais: statement.balance > 0 }"
                  >
                    {{ statement.balance > 0 ? "+" : "" }}R$
                    {{ formatMoney(statement.balance, 2, ",") }}
                  </p>
                </b-col>
              </b-row>

              <b-row class="item-dados">
                <b-col class="sumary">
                  <b-row>
                    <b-col>
                      <p class="label-campo">
                        <span class="title-item">Código</span>
                        <span>#{{ statement.id }}</span>
                      </p>

                      <p class="label-campo">
                        <span class="title-item">Data</span>
                        <span>{{ statement.created_at | datetime }}</span>
                      </p>

                      <p class="label-campo" v-if="statement.type != 'SALE'">
                        <span class="title-item">Tipo de Transação</span>
                        <span>{{ getType(statement.type) }}</span>
                      </p>

                      <p
                        class="label-campo"
                        v-if="
                          statement.type == 'SALE' ||
                            statement.type == 'REFUNDED'
                        "
                      >
                        <span class="title-item">Disponível em</span>
                        <span>{{ statement.available_date | date }}</span>
                      </p>

                      <p
                        class="label-campo"
                        v-if="
                          statement.type != 'WITHDRAW' ||
                            statement.type != 'WITHDRAW_FEE'
                        "
                      >
                        <span
                          class="title-item"
                          v-if="
                            statement.type == 'SALE' ||
                              statement.type == 'REFUNDED'
                          "
                          >ID da Venda</span
                        >
                        <span
                          class="title-item"
                          v-if="
                            statement.type == 'ANTECIPATION' ||
                              statement.type == 'ANTECIPATION_FEE' ||
                              statement.type == 'ANTECIPATION_REMAINING' ||
                              statement.type == 'ANTECIPATION_BALANCE'
                          "
                          >ID da Antecipação</span
                        >
                        <span>{{ statement.sale_id }}</span>
                      </p>

                      <p
                        class="label-campo"
                        v-if="
                          statement.type == 'WITHDRAW' ||
                            statement.type == 'WITHDRAW_FEE'
                        "
                      >
                        <span class="title-item">ID do Saque</span>
                        <span>{{ statement.withdraw_id }}</span>
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <!-- Product -->
              <b-row
                class="item-dados"
                v-if="statement.type == 'SALE' || statement.type == 'REFUNDED'"
              >
                <b-col>
                  <h5 class="titulo-item">Produto</h5>

                  <b-col cols="12">
                    <div class="product-main">
                      <div class="img-product">
                        <img
                          :src="statement.product.images[0].path"
                          v-if="
                            !loading &&
                              statement.product &&
                              statement.product.images.length
                          "
                        />
                      </div>
                      <div
                        class="name-product"
                        v-if="!loading && statement.product"
                      >
                        <span>Produto: #{{ statement.product.id }}</span>
                        <p>{{ statement.product.name }}</p>
                      </div>
                    </div>
                  </b-col>
                </b-col>
              </b-row>

              <!-- Client -->
              <b-row
                class="item-dados"
                v-if="statement.type == 'SALE' || statement.type == 'REFUNDED'"
              >
                <b-col>
                  <h5 class="titulo-item">Cliente</h5>

                  <p class="label-campo">
                    <span class="title-item">Nome</span>
                    <span>{{ statement.sale.client.name }}</span>
                  </p>
                  <p class="label-campo">
                    <span class="title-item">Endereço de e-mail</span>
                    <span>{{ statement.sale.client.email }}</span>
                  </p>
                  <p class="label-campo">
                    <span class="title-item">Telefone</span>
                    <span>{{ statement.sale.client.cellphone }}</span>
                  </p>
                </b-col>
              </b-row>
            </b-overlay>
          </b-col>
          <b-col v-if="has_antecipation">
            <b-overlay :show="loading">
              <b-row class="item-dados">
                <b-col class="sumary">
                  <span>Valor Liquído</span>
                  <p class="dados-campo amount-available mt-3">
                    R$ {{ formatMoney(antecipation.total, 2, ",") }}
                  </p>
                </b-col>
              </b-row>

              <b-row class="item-dados">
                <b-col class="sumary">
                  <b-row>
                    <b-col>
                      <p class="label-campo">
                        <span class="title-item">Código</span>
                        <span>{{ antecipation.id }}</span>
                      </p>
                      <p class="label-campo">
                        <span class="title-item">Dt. Criação</span>
                        <span>{{ antecipation.created_at | datetime }}</span>
                      </p>
                      <p class="label-campo">
                        <span class="title-item">Tipo de Transação</span>
                        <span>Antecipação de Saldo</span>
                      </p>
                      <p class="label-campo">
                        <span class="title-item">Processado em</span>
                        <span>{{ antecipation.updated_at | datetime }}</span>
                      </p>
                      <p class="label-campo">
                        <span class="title-item">Taxa</span>
                        <span
                          >R$ {{ formatMoney(antecipation.fee, 2, ",") }}</span
                        >
                      </p>
                      <p class="label-campo">
                        <span class="title-item">Valor Bruto</span>
                        <span
                          >R$
                          {{ formatMoney(antecipation.amount, 2, ",") }}</span
                        >
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <!-- Product -->
              <b-row
                class="item-dados"
                v-if="statement.type == 'SALE' || statement.type == 'REFUNDED'"
              >
                <b-col>
                  <h5 class="titulo-item">Produto</h5>

                  <b-col cols="12">
                    <div class="product-main">
                      <div class="img-product">
                        <img
                          :src="statement.product.images[0].path"
                          v-if="
                            !loading &&
                              statement.product &&
                              statement.product.images.length
                          "
                        />
                      </div>
                      <div
                        class="name-product"
                        v-if="!loading && statement.product"
                      >
                        <span>Produto: #{{ statement.product.id }}</span>
                        <p>{{ statement.product.name }}</p>
                      </div>
                    </div>
                  </b-col>
                </b-col>
              </b-row>

              <!-- Client -->
              <b-row
                class="item-dados"
                v-if="statement.type == 'SALE' || statement.type == 'REFUNDED'"
              >
                <b-col>
                  <h5 class="titulo-item">Cliente</h5>

                  <p class="label-campo">
                    <span class="title-item">Nome</span>
                    <span>{{ statement.sale.client.name }}</span>
                  </p>
                  <p class="label-campo">
                    <span class="title-item">Endereço de e-mail</span>
                    <span>{{ statement.sale.client.email }}</span>
                  </p>
                  <p class="label-campo">
                    <span class="title-item">Telefone</span>
                    <span>{{ statement.sale.client.cellphone }}</span>
                  </p>
                </b-col>
              </b-row>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-sidebar>
  </div>
</template>

<script>
import AccountStatementsService from "@/services/resources/AccountStatementsService";
const service = AccountStatementsService.build();
import AntecipationService from "@/services/resources/AntecipationService";
const serviceAntecipation = AntecipationService.build();

import Money from "@/mixins/money";

export default {
  name: "DetalhesExtrato",
  mixins: [Money],
  data() {
    return {
      loading: true,
      antecipation_id: -1,
      statement_id: -1,
      visible: false,
      has_antecipation: false,
      antecipation: null,
      statement: {
        product: {
          name: "",
          description: "",
          images: [{ path: null }],
        },
        sale: {
          client: {
            name: null,
            email: null,
            cellphone: null,
          },
        },
      },
    };
  },
  computed: {
    isSeller() {
      return this.$store.getters.isSeller;
    },
  },
  methods: {
    getType(type) {
      let r = "";
      switch (type) {
        case "SALE": {
          r = "VENDA";
          break;
        }
        case "REFUNDED": {
          r = "REEMBOLSO";
          break;
        }
        case "WITHDRAW": {
          r = "SAQUE";
          break;
        }
        case "WITHDRAW_FEE": {
          r = "TAXA DE SAQUE";
          break;
        }
        case "ANTECIPATION": {
          r = "ANTECIPAÇÃO";
          break;
        }
        case "ANTECIPATION_FEE": {
          r = "TAXA DE ANTECIPAÇÃO";
          break;
        }
        case "ANTECIPATION_BALANCE": {
          r = "SALDO DA ANTECIPAÇÃO";
          break;
        }
        case "ANTECIPATION_REMAINING": {
          r = "ANTECIPAÇÃO RESTANTE";
          break;
        }
      }
      return r;
    },
    resetUrl() {
      if (Object.values(this.$route.query).length) {
        this.$router.push({ query: {} });
      }
    },
    openPanel(id, antecipation) {
      this.visible = true;
      this.has_antecipation = false;
      if (!antecipation) {
        this.statement_id = id;
        this.fetchStatement();
      } else {
        this.antecipation_id = id;
        this.fetchAntecipation();
      }
    },
    fetchAntecipation() {
      this.has_antecipation = true;
      this.antecipation = {};
      this.statement = {
        product: {
          name: "",
          description: "",
          images: [{ path: null }],
        },
      };

      this.loading = true;

      let data = {
        id: this.antecipation_id,
      };

      serviceAntecipation
        .read(data)
        .then((response) => {
          if (response.id) {
            this.antecipation = response;
          } else {
            this.visible = false;
            this.$bvToast.toast("Antecipação não encontrada", {
              title: "Antecipação",
              variant: "info",
              autoHideDelay: 9000,
              appendToast: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchStatement() {
      this.has_antecipation = false;
      this.statement = {
        product: {
          name: "",
          description: "",
          images: [{ path: null }],
        },
      };

      this.loading = true;

      let data = {
        id: this.statement_id,
      };

      service
        .read(data)
        .then((response) => {
          this.statement = response;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.panel {
  .sumary {
    font-size: 13px;
    // padding-top: 20px;
    // padding-bottom: 20px;
  }

  .product-main {
    display: grid;
    grid-template-columns: 0.5fr 3fr;
    gap: 20px;
    align-items: center;
  }
  .img-product {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img-product,
  .img-product img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #ededf0;
    object-fit: cover;
  }
  .name-product span {
    font-size: 11px;
    color: #81858e;
  }
  .name-product p {
    font-weight: 600;
  }
}
.container-sidebar {
  padding: 40px;
  padding-top: 20px;
}
.dados-campo {
  font-weight: normal;
  font-size: 13px;
  margin-top: 3px;
}
.item-dados {
  // border: 1px solid ;
  padding: 22px 0;
}
.item-dados + .item-dados {
  border-top: 0.5px solid #ededf0;
}
.titulo-item {
  font-size: 18px;
  font-weight: 600;
  color: $black;
  margin-bottom: 15px;
}
.label-campo {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.title-item {
  font-size: 12px;
  color: #81858e;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}
.label-campo span {
  font-weight: 600;
}
.label-campo + .label-campo {
  margin-top: 10px;
}
.amount-available {
  font-size: 32px;
  font-weight: 600;
  color: #333;
  padding-bottom: 10px;
}
.amount-available.mais {
  color: #002363;
}
</style>
